import config from "@/config/config";
// 获取token
export function getToken() {
  if (!localStorage.getItem(config.tokenKey)) return false
  return JSON.parse(localStorage.getItem(config.tokenKey)).token;
}

// 设置token
export function setToken(token) {
  return localStorage.setItem(
    config.tokenKey,
    JSON.stringify({ token, expire: Date.now() + 7 * 24 * 60 * 60 * 1000 })
  );
}

// 设置用户信息
export function setUserInfo(userInfo) {
  return localStorage.setItem(config.userInfo, userInfo);
}

// 获取用户信息
export function getUserInfo() {
  return JSON.parse(localStorage.getItem(config.userInfo));
}


// 设置父级的host
export function setTargetHost(targetHost) {
  return localStorage.setItem('targetHost', targetHost)
}

// 获取父级的host
export function getTargetHost() {
  return localStorage.getItem('targetHost')
}



// 获取父级的host
export function getIndex() {
  return GetQueryString('index')
}




export const getFloat =(number, n)=> {
  n = n ? parseInt(n) : 0;
  if(n <= 0) {
    return Math.round(number);
  }
  number = Math.round(number * Math.pow(10, n)) / Math.pow(10, n);
  number = Number(number).toFixed(n);
  return number;
};

export const param = (obj) => {
  let result = "";
  let item;
  for (item in obj) {
    // console.log(obj[item]);
    // if (obj[item] && String(obj[item])) {
    result += `&${item}=${obj[item]}`;
    // }
  }
  if (result) {
    result = "?" + result.slice(1);
  }
  return result;
};
export const GetQueryString=(name)=>
{
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return unescape(r[2]);
  }
  return null;
};
