<template>
  <div id="ezviz_viewer"></div>
</template>
<script>
  import EZUIKit from "../../lib/ezuikit.js";
  import bus from "../../utils/bus";
  import $ from "jquery";
  import {getIndex, getTargetHost} from "@/utils/util";

  export default {
    data() {
      return {
        EZUIKitPlayer: "",
      };
    },
    created() {
      this.index=getIndex()

      this.initEvents();
    },
    mounted() {
    },
    methods: {
      initEvents() {
        bus.$on("ezvizUserInfo", (r) => {
          this.InitEZUIKitPlayer(r);
        });

        bus.$on("reconnect", () => {
          this.EZUIKitPlayer.play();
        });

        bus.$on("resizeCanvas", ({width, height}) => {
          if (this.EZUIKitPlayer) {
            if (height) {
              this.EZUIKitPlayer.reSize(height / (9 / 16), height);
            } else {
              this.EZUIKitPlayer.reSize(
                $(document).height() / (9 / 16),
                $(document).height()
              );
            }
          }
        });

        bus.$on("getFrameImage", () => {
          this.EZUIKitPlayer.capturePicture("ezvizImage", (r) => {
            if (window.require) {
              const {ipcRenderer} = window.require("electron");
              ipcRenderer.sendToHost("frameImage", {
                url: r.base64,
              });
            } else {
              window.parent.postMessage(
                {
                  channel: "frameImage",
                  data: {
                    url: r.base64,
                    index:this.index,
                  },
                },
                getTargetHost()
              );
            }
          });
        });

        window.addEventListener("resize", () => {
          this.resizeCanvas();
        });
      },
      resizeCanvas() {
        if (this.EZUIKitPlayer) {
          this.EZUIKitPlayer.reSize(
            $(document).height() / (9 / 16),
            $(document).height()
          );
        }
      },
      InitEZUIKitPlayer(r) {
        this.EZUIKitPlayer = new EZUIKit.EZUIKitPlayer({
          id: "ezviz_viewer", // 视频容器ID
          accessToken: r.token,
          url: r.url,
          autoplay: true,
          height: 600,
          width: 400,
          handleSuccess: (r) => {
            this.resizeCanvas();

            if (window.require) {
              const {ipcRenderer} = window.require("electron");
              ipcRenderer.sendToHost("play-success"); // 向原生发送信息
            } else {
              window.parent.postMessage(
                {
                  channel: "play-success",
                  data: {
                    index:this.index,
                  },
                },
                getTargetHost()
              );
            }
          },
          handleError: (e) => {
            if (window.require) {
              const {ipcRenderer} = window.require("electron");
              ipcRenderer.sendToHost("play-error"); // 向原生发送信息
            } else {
              window.parent.postMessage(
                {
                  channel: "play-error",
                  data: {
                    index: this.index,
                  },
                },
                getTargetHost()
              );
            }
          },
        });
      },
    },
  };
</script>
<style lang="scss">
  #ezviz_viewer-wrap {
    position: absolute;
    height: 100% !important;
    left: 50%;
    top: 0;
    transform: translateX(-50%);

    #ezviz_viewer {
      width: 100% !important;
      height: 100% !important;

      .hkEzvizVideo_1draw-window,
      .hkEzvizVideo_2draw-window {
        display: none;
      }
    }
  }
</style>
